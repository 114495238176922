<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.stock_taking')">
            <CustomTable  ref="stockTakingTable" :fields="columnDefsStockTaking" api-url="/api/client/v1/stock_takings/">
                <template #buttons>
                    <b-button variant="primary" @click="startStockTaking">
                        {{ $t('material.stock_taking.start_stock_taking') }}
                    </b-button>
                </template>
                <template #state="{item}">
                    <b-badge variant="warning" v-if="item.editing_in_progress && item.confirmed">
                        {{ $t('general.yes') }}
                    </b-badge>
                    <b-badge variant="success" v-else-if="item.confirmed">
                        {{ $t('general.no') }}
                    </b-badge>
                    <b-badge variant="danger" v-else>
                        {{ $t('general.not_included_in_stock') }}
                    </b-badge>
                </template>
                <template #actions="{item}">
                    <div class="d-flex justify-content-center">
                        <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="remove(item)">
                            {{ $t('general.delete') }}
                        </b-button>
                        <b-button class="mr-1 m-0 button d-flex justify-content-center align-items-center" :variant="item.confirmed?'warning':'success'"  size="sm" style="width: 60px;" @click="editRow(item)">
                            <span v-if="item.confirmed"> {{ $t('general.edit') }}</span>
                            <span v-else> {{ $t('general.continue') }}</span>
                        </b-button>
                        <b-button class="m-0 button" variant="secondary"  size="sm" @click="viewStockTaking(item)" :disabled="!item.confirmed">
                            <span style="white-space: nowrap;">
                                {{ $t('general.view_printout') }}
                            </span>
                        </b-button>
                    </div>
                </template>
            </CustomTable>
            <report-preview-modal ref="stockTakingReportModal" />

        </b-card>
    </b-overlay>
</template>

<script>
    import {BBadge, BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import ReportPreviewModal from '@/views/components/ReportPreviewModal.vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components: {CustomTable, BBadge, ReportPreviewModal, BCard, BOverlay, BButton},
        computed:{
            columnDefsStockTaking() {
                return [
                    {
                        key: 'stock_taking_code',
                        label: this.$t('table_fields.code'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'stock_taking_code.w',
                        permissions: true,
                        minWidth: 100
                    },
                    {
                        key: 'timestamp',
                        label: this.$t('table_fields.created'),
                        filter: true,
                        permissions: true,
                        formatter: Formaters.formatDateOnly,
                        filterType: 'date',
                        minWidth: 75
                    },
                    {
                        key: 'warehouse_id',
                        label: this.$t('table_fields.warehouses'),
                        filter: true,
                        filterType: 'select',
                        filterParamsName: 'name',
                        filterParams: this.warehouses,
                        reduce: ele => ele.id,
                        formatter: this.getWarehouseName,
                        filterFormatter: this.formatFilterPaymentType,
                        permissions: true,
                        minWidth: 150
                    },
                    {
                        key: 'items',
                        label: this.$t('table_fields.number_of_products_on_stock_taking'),
                        filter: false,
                        filterType: 'input',
                        formatter: this.getNumberOfItemsOnStockTaking,
                        filterFormatter: this.formatFilterPaymentType,
                        permissions: true,
                        minWidth: 100
                    },
                    {
                        key: 'state',
                        label:  this.$t('table_fields.changes'),
                        permissions: true,
                        minWidth: 160
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true
                    }
                ]

            }
        },
        data() {
            return {
                businessUnitRooms: [],
                warehouses: [],
                showLoader: false,
                loaded: false,
                fileName: '',
                apiUrl: ''
            }
        },
        methods: {
            getNumberOfItemsOnStockTaking(value) {
                if (value) {
                    return value.length
                }
                return '/'
            },
            async startStockTaking() {
                try {
                    const response = await this.$http.post('/api/client/v1/stock_takings_temporary/create/000000000000000000000000')
                    const stockTakingTemporary = response.data ?? []

                    await this.$router.push({name: 'edit_stock_taking', params:{id: stockTakingTemporary.id}})
                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/business_units/')
                    const response2 = this.$http.get('/api/client/v1/warehouses/')

                    const response = await Promise.all([response1, response2])
                    this.businessUnitRooms = response[0].data ?? []
                    this.warehouses = response[1].data ?? []

                    this.warehouses = response[1].data ?? []

                    const indexWarehouses = this.columnDefsStockTaking.findIndex(ele => ele.key === 'warehouse_id')

                    if (indexWarehouses >= 0) {
                        this.columnDefsStockTaking[indexWarehouses].filterParams = this.warehouses
                    }

                    this.loaded = true
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async viewStockTaking(data) {
                this.fileName = `Inventura_${data.stock_taking_code}.pdf`
                this.apiUrl = `/api/reports/v1/printout/stock_taking/${data.id}`
                await this.$refs.stockTakingReportModal.show(this.fileName, this.apiUrl)
            },
            async editRow(data) {
                try {
                    const response = await this.$http.post(`/api/client/v1/stock_takings_temporary/create/${data.id}`)
                    const stockTakingTemporary = response.data ?? []

                    await this.$router.push({name: 'edit_stock_taking', params:{id: stockTakingTemporary.id}})
                } catch (error) {
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            async remove(data) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/stock_takings/${data.id}`)

                    this.$printSuccess(this.$t('print.success.delete'))

                    if (this.$refs.stockTakingTable) {
                        await this.$refs.stockTakingTable.reloadData()
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getWarehouseName(value) {
                if (value) {
                    const item = this.warehouses.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            getDate(params) {
                if (params.data.date && params.data.time) {
                    return `${this.dayjs(params.data.date).format('DD.MM.YYYY')} ${this.dayjs(params.data.time).format('hh:mm')}`
                }
                return '/'
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
